import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const Wrapper = styled.section`
  h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 400px) {
  }
`

export default function privacy() {
  return (
    <Layout relative={false}>
      <Wrapper className="max-w-[980px] mx-auto mt-4 mb-4">
        <h3>Privacy Policy</h3>
        <p>
          Bruno Lafon Selection privacy and security measures are designed with
          the strictest security in mind, so any personal information that you
          provide to Bruno Lafon Selection is used for the purpose of receiving
          Bruno Lafon Selection communications. Bruno Lafon Selection privacy
          policy is outlined below and tells you how we collect personal
          information, what information we collect, how we use it, to whom we
          will disclose it and how you can access, change and remove your
          information. ​
        </p>
        <h3>HOW WE COLLECT THE INFORMATION</h3> ​
        <p>
          There are many ways that Bruno Lafon Selection may obtain information
          from you:
          <br />
          <br /> (1)via sign up on brunolafonselection.com) <br /> (2) from an
          online, email, retail, fax or telephone conversation
          <br /> (3) from navigational information about where visitors go on
          our website; <br />
          (4) any time you submit a contact form
          <br />
          This information may be collected by Bruno Lafon Selection or by
          vendors/service providers.
        </p>
        <h3>THE INFORMATION WE COLLECT</h3>​
        <p>
          We also may collect additional non-personally identifiable information
          (for example, browser version, IP address) which will provide
          information regarding the general use of our website. The navigational
          information allows us to see which areas of our website are most
          visited and helps us improve the quality of your online experience by
          recognizing and delivering more of the most desired features and
          services.
        </p>{" "}
        ​<h3>HOW WE USE THE INFORMATION </h3>​
        <p>
          All personal information that we obtain about you will be recorded,
          used and protected by us in accordance with current data protection
          legislation and this privacy policy. By providing us with your
          personal information, you consent to us processing this personal
          information in one or more of the ways described in this privacy
          policy. We will primarily use your personal information to communicate
          with you.To send you information/materials via email or mail about
          products, offers and news we think could interest you. This
          information may relate to products, offers and news of Bruno Lafon
          Selection. To analyze visits to the website and learn about the
          interests of our visitors in the aggregate and to better understand
          your personal interests and needs.
        </p>
        ​<h3>COOKIES</h3>
        <p>
          A cookie is a small removable data file that is stored by your web
          browser on your computer. Cookies are only used by Bruno Lafon
          Selection to allow you to use Bruno Lafon Selection website in the
          most efficient manner. We use cookies to: Recognize you when you
          return to our website Analyze visitor behavior You can choose to have
          your computer warn you each time a cookie is being sent or you can
          choose to turn off all cookies. You do this through your browser
          settings. Please note, however, that by deleting our cookies or
          disabling future cookies you may not be able to access certain areas
          or features of our website. We will still know how many of our emails
          are opened and we will automatically receive your IP address, a unique
          identifier for your computer or other access device but we will not
          identify you as an individual.
        </p>
        <h3>SHARING INFORMATION WITH THIRD PARTIES </h3>​{" "}
        <p>
          Bruno Lafon Selection will never sell any information about you to
          anyone. We are part of a large Industry and we may share information
          with our affiliated vendors. We engage third parties to perform
          services in connection with the operation of our business. Examples of
          these services include payment processing and authorization, fraud
          protection and credit risk reduction, product customization, order
          fulfillment and shipping, marketing and promotional material
          distribution, website evaluation, data analysis and, where applicable,
          data cleansing. We may need to share personal information with these
          third parties so that they can perform these services, however, we
          limit the personal information that we give to them and authorize them
          to use this information only for the purpose of performing those
          services. We also may share non-personal information, such as the
          number of users who visited the website during a specific time period
          or who purchased a specific product through the website, with our
          marketing partners, advertisers and others from time to time. This
          information generally is shared in an aggregated form. We may release
          personal information if we believe in good faith that: the law or
          legal process requires it; if we have been advised by counsel; we have
          received a valid administrative request from a law enforcement agency;
          or such release is necessary to protect the rights, property or safety
          of Bruno Lafon Selection, or any of our respective affiliates,
          business partners, customers or others.{" "}
        </p>
        <h3>EMAIL</h3>
        <p>
          Bruno Lafon Selection has a Company policy not to flood people with
          email correspondence, but simply to give you periodic information so
          you can stay up to date on what Bruno Lafon Selection is offering.
          Bruno Lafon Selection will send emails to you only if you ask for them
          and you can easily unsubscribe at any time you want by following the
          instructions contained in the email. All emails you receive will
          provide you with information as to how to get off the email mailing
          list if you so desire.
        </p>
        <h3>SECURITY</h3>
        <p>
          We use industry standard SSL (Secure Sockets Layer) encryption to
          protect the confidentiality of your personal information. ​
        </p>
        <h3>OPT OUT / UNSUBSCRIBE ​ </h3>
        <p>
          You may request to stop receiving communications from Bruno Lafon
          Selection at any time. All emails you receive will provide you with
          information as to how to get off the email mailing list if you so
          desire. It takes some time to put opt-outs in effect so you may still
          receive catalogs or emails from Bruno Lafon Selection for several
          weeks after your request is received.
        </p>
        ​ <h3>UPDATES TO PRIVACY INFORMATION </h3>
        <p>
          Please review the site periodically to see any updates to the
          information provided here. This privacy policy was last updated on
          July, 22 2022.
        </p>
      </Wrapper>
    </Layout>
  )
}
